<template>
  <div>
    <div class="tg_booking_system_nsxs">
      <div class="container">
        <div class="wrap_detail_ticket_pop">
          <div class="tg_flatpickr_nsx">
            <figure id="tg_date_choose" name class="flatpickr-input active">
              <img :src="urlImage('/template/images/big-calendar-icon.png')" @click="addClassCss" />
            </figure>
          </div>

          <div class="wrap_date_choose" style="postion: relative">
            <button
              class="slick-prev slick-arrow"
              aria-label="Previous"
              type="button"
              style
              @click="setDate(-1)"
            >Previous</button>
            <button
              class="slick-next slick-arrow"
              aria-label="Next"
              type="button"
              style
              @click="setDate(1)"
            >Next</button>
            <div class="tg_lang click-ul-tog" ref="button" @click="isShowSelect = !isShowSelect">
              <p class="current_lang">{{ optionSelected }}</p>
              <ul
                :class="{ 'ul-tog': true, 'show-date': isShowSelect }"
                v-closable="{
                  exclude: ['button'],
                  handler: 'dropIt',
                }"
          >
            <li
              class="tab-link currents"
              data-tab="tabs-1"
              @click="changeOptionCalendar('WEEK')"
            >WEEK</li>
            <li class="tab-link" data-tab="tabs-2" @click="changeOptionCalendar('MONTH')">MONTH</li>
            <!-- <li class="tab-link currents" data-tab="tabs-3" @click="changeOptionCalendar('DAY')">DAY</li> -->
          </ul>
        </div>
      </div>
          <!-- <p v-if="optionSelected==='DAY'" class="tg_day_ver2">{{ this.getWeek }}</p> -->
           <p v-if="optionSelected==='MONTH'" class="tg_month_ver2">{{ this.getWeek }}</p>
            <p v-else class="tg_date_ver2">{{ this.getWeek }}</p>
    </div>
    <!-- <div class="tg_my_booking_fy">
      <ul class="tg_color_code_nsx">
        <li class="tg_yellow">予約済</li>
        <li class="tg_gray">予約不可</li>
        <li class="tg_white">空</li>
      </ul>
      <ul class="tg_my_booking_fee">
        <li>
          <a @click.prevent="showModalMyBooking()">My Booking</a>
        </li>
        <li>
          <a @click.prevent="redirectToRental()">Rental fee</a>
        </li>
        <li>
          <a href="javascript:void(0)" @click="goToContact()">Contact</a>
        </li>
      </ul>
    </div> -->
    <div class="tg_my_booking_fy">
          <ul class="tg_my_booking_fee">
            <li><a href="javascript:void(0)" @click.prevent="showModalMyBooking()">My Booking</a></li>
            <li><a href="javascript:void(0)" @click.prevent="redirectToRental()">Rental fee</a></li>
            <li><a href="javascript:void(0)" @click="goToContact()">Contact</a></li>
          </ul>
          <ul class="tg_color_code_nsx">
            <li class="tg_yellow">予約済</li>
            <li class="tg_gray">予約不可</li>
            <li class="tg_white">空</li>
          </ul>

        </div>
    <div id="tabs-1" class="tabs-contents currents">
      <!-- <p class="tg_sos_ft">工ラーテキストはここで表示する</p> -->
      <div class="slide_table_week_ft">
        <div class="tg_table_booking_system">
          <CalendarWeek
            v-if="optionSelected==='WEEK'"
            :list-week="arrWeek"
            :table-calendar="showDataBooking()"
          />
          <!-- <CalendarDay
            v-else-if="optionSelected==='DAY'"
            :day="dayMoment"
            :table-calendar="showDataBooking()"
              />-->
              <CalendarMonth
                v-else-if="optionSelected==='MONTH'"
                :arr-week-of-month="arrWeek"
                :table-calendar="showDataBooking()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- popup bos014 -->
    <div class="popup_bos14" v-bind:class="{'popup':checkPopupFindBooking}">
      <div class="content_popup">
        <h2 class="popup_title">My booking</h2>
        <div class="form_group">
          <div class="list_group error-message-custom" v-if="errors.length>0">
            <label></label>
            <span class="bos14_note">{{errors}}</span>
          </div>
          <form>
            <div class="bos14_form">
              <div class="list_group" v-bind:class="{'list_group_2':checkError }">
                <label>予約番号</label>
                <div class="bos14_ipu">
                  <input maxlength="8" type="text" v-model="formFindBooking.code" placeholder="">
                </div>
              </div>
              <div class="list_group" v-if="errors['code']">
                <label></label>
                <span class="bos14_note">{{errors['code'][0]}}</span>
              </div>
              <div class="list_group list_group_2">
                <label>メールアドレス</label>
                <div class="bos14_ipu">
                  <input maxlength="100" type="email" v-model="formFindBooking.email" placeholder="">
                </div>
              </div>
              <div class="list_group" v-if="errors['email']">
                <label></label>
                <span class="bos14_note">{{errors['email'][0]}}</span>
              </div>
            </div>
            <div class="bos14_btn">
              <button @click.prevent="closePopupFindBooking()" class="bos14_close_btn">閉じる</button>
              <button @click.prevent="findBooking()" type="submit" class="bos14_confirm_btn">確認</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- end popup bos014 -->

    <!-- poup bos013 -->
    <div class="popup_bos13" :class="{'popup':checkPopupStatusBooking}">
      <div class="content_popup">
        <h2 class="popup_title">予約番号: {{dataBooking.code}}</h2>
        <div class="form_group">
          <form v-if="dataBooking.status != 5">
            <h3>予約が完了しました。</h3>
            <span>この度は、「 Booking system」を利用して頂き、ありがとうございます。予約情報は登録したメールアドレスに送信しましたので、ご確認お願い致します。</span>
            <button type="submit" @click="$router.go(0)">閉じる</button>
          </form>
          <form v-else>
            <span>{{this.$t('ER_22')}}</span>
            <button type="submit" @click="$router.go(0)">閉じる</button>
          </form>
        </div>
      </div>
    </div>
    <!-- end poup bos013 -->

    <!-- poup er_01 -->
    <div class="popup_er_01" :class="{'popup':checkNotiErr01}">
      <div class="content_popup">
        <h2 class="popup_title">通知</h2>
        <div class="form_group">
          <form>
            <span>{{this.$t('ER_01')}}</span>
            <a href="javascript:void(0)" class="btnCloseErr" @click="closePopupErr01()">閉じる</a>
          </form>
        </div>
      </div>
    </div>
    <!-- end poup er_01 -->

    <!-- poup er_10 -->
    <div class="popup_er_10" :class="{'popup':checkNotiErr10}">
      <div class="content_popup">
        <h2 class="popup_title">通知</h2>
        <div class="form_group">
          <form>
            <span>{{this.$t('ER_10')}}</span>
            <a href="javascript:void(0)" class="btnCloseErr" @click="closePopupErr10()">閉じる</a>
          </form>
        </div>
      </div>
    </div>
    <!-- end poup er_01 -->
  </div>
</template>

<script>
import flatpickr from "flatpickr";
import CalendarMonth from "@/view/pages/calendar/CalendarMonth";
import CalendarDay from "@/view/pages/calendar/CalendarDay";
import CalendarWeek from "@/view/pages/calendar/CalendarWeek";
import moment from "moment";
import {GET_DATA_CALENDAR, GET_DATA_FIND_BOOKING} from "@/core/services/store/calendar.module";
import {CHECK_STATUS_BOOKING} from "@/core/services/store/booking.module";
import { STATUS_CODE } from "@/core/config/constant";
import { ENV } from "@/core/config/env";
import {CHANGE_STATUS} from "@/core/services/store/order.module";

const { AUTHENTICATE, ERROR_SERVER, SUCCESS, BAD_REQUEST, HTTP_BAD_REQUEST, NOT_FOUND } = STATUS_CODE;

export default {
  name: "Calendar",
  components: { CalendarDay, CalendarWeek, CalendarMonth },
  data() {
    return {
      data: {},
      isShowSelect: false,
      year: "",
      month: "",
      loading: false,
      querySearch: {
        page: ""
      },
      search: "",
      form: {
        time: ""
      },
      arrDayOfWeek: [],
      currentWeek: 0,
      arrHour: [
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00"
      ],
      firstDayOfWeek: "",
      lastDayOfWeek: "",
      optionSelected: "WEEK",
      arrWeek: [],
      dayMoment: "",
      check: true,
      detail_code: '',
      checkPopupFindBooking: false,
      checkPopupStatusBooking: false,
      formFindBooking: {
        code: '',
        email: '',
      },
      errors: [],
      id_order: '',
      checkError: false,
      checkShowErr: false,
      listEventDateRestTime: [],
      listEventDateBooking: [],
      dataBooking: {},
      checkNotiErr01: false,
      checkNotiErr10: false,
    };
  },

  computed: {
    getWeek() {
      let strWeek = "";
      if (this.optionSelected == "DAY") {
        strWeek = this.dayMoment;
      }
      else if(this.optionSelected == "MONTH"){

        strWeek = moment(this.firstDayOfWeek).format('YYYY/MM');
      }
      else {
        strWeek = this.firstDayOfWeek + " ~ " + this.lastDayOfWeek;
      }
      return strWeek;
    }
  },

  updated() {
    this.$nextTick(() => {

    const dates =  document.getElementsByClassName("date-choose");
    const rest_times =  document.getElementsByClassName("bg_gray_ver2");
    const bookeds =  document.getElementsByClassName("bg_yellow_ver2");
    const weeks = document.getElementsByClassName("change_week");
    let self = this;

    const showNotiEr_01 = () => {
      this.notifyError(this.$t('ER_01'));
    }

    const showNotiEr_10 = () => {
      this.notifyError(this.$t('ER_10'));
    }

    Array.from(dates).forEach(date => date.addEventListener('click', function (event) {
      let date = event.target.getAttribute("attr-date");
      let time = event.target.getAttribute("attr-time");
      let currentDate = moment().format('YYYY-MM-DD');
      let nextDate = moment(currentDate).add(7, 'days').format('YYYY-MM-DD');
      if (date < currentDate) {
        self.checkNotiErr01 = true;
      } else if (date <= nextDate) {
        self.checkNotiErr10 = true;
      } else {
        self.$router.push({name: 'booking', params: {date: date, time: time}})
      }
    }));

    Array.from(rest_times).forEach(rest_time => rest_time.addEventListener('click', function (event) {
      self.checkNotiErr01 = true;
    }));

    // Array.from(rest_times).forEach(rest_time => {
    //   let date = rest_time.getAttribute("attr-date");
    //   let time = rest_time.getAttribute("attr-time");
    //   if (!self.listEventDateRestTime[date]) {
    //     rest_time.addEventListener('click', showNotiEr_01, true);
    //     self.listEventDateRestTime[date] = []
    //     self.listEventDateRestTime[date].push(time);
    //   } else if (!self.listEventDateRestTime[date].includes(time)) {
    //     rest_time.addEventListener('click', showNotiEr_01, true);
    //     self.listEventDateRestTime[date].push(time);
    //   }
    // });

    Array.from(bookeds).forEach(booked => booked.addEventListener('click', function (event) {
      self.checkPopupFindBooking = true;
    }));

    Array.from(weeks).forEach(date => date.addEventListener('click', function (event) {
      date = event.currentTarget.getAttribute("attr-date");
      self.optionSelected = "WEEK";
      self.firstDayOfWeek = moment(date).startOf('isoWeek').format('YYYY/MM/DD');
      self.lastDayOfWeek = moment(date).endOf('isoWeek').format('YYYY/MM/DD');
      var days = [];
      var day = moment(self.firstDayOfWeek, "YYYY/MM/DD");
      var week = [];
      while (day <= moment(self.lastDayOfWeek, "YYYY/MM/DD")) {
        days.push(day.clone().format("YYYY-MM-DD"));
        week.push({
          day: day
            .clone()
            .locale("ja")
            .format("dddd")
            .replace("曜日", ""),
          date: day.clone().format("MM/DD")
        });
        day = day.clone().add(1, "d");
      }
      week.unshift({ day: "", date: "" });
      self.arrWeek = week;
      self.arrDayOfWeek = days;
    }));

    });
  },

  async created() {
    await this.setDate(0);
    if (this.$route.params.flag && this.$route.params.code) {
      this.check = this.$route.params.flag;
      this.detail_code = this.$route.params.code;
    }

    if (this.$route.query.token) {
      await this.checkStatusBooking();
    }
  },

  mounted() {
    flatpickr("#tg_date_choose", {
      defaultDate: "today",
      dateFormat: "Y/m/d",
      disableMobile: true,
      locale: {
        firstDayOfWeek: 1,
        weekdays: {
          shorthand: ["日", "月", "火", "水", "木", "金", "土"]
        },
        months: {
          shorthand: [
            "一月",
            "二月",
            "三月",
            "四月",
            "五月",
            "六月",
            "七月",
            "八月",
            "九月",
            "十月",
            "十一月",
            "十二月"
          ],
          longhand: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月"
          ]
        }
      },
      plugins: [
        ShortcutButtonsPlugin({
          button: [
          {
              label: "Yesterday"
          },
          {
              label: "今日"
          },
          {
              label: "Tomorrow"
          }
          ],
          label: "",
          onClick: (index, fp) => {
            switch (index) {
              case 0:
              // this.date = new Date(Date.now() - 24 * 60 * 60 * 1000);
              break;
              case 1:
              this.date = moment().format("YYYY-MM-DD");
              this.changeFlatpickr(this.date);
              break;
              case 2:
              // this.date = new Date(Date.now() + 24 * 60 * 60 * 1000);
              break;
            }
            fp.setDate(this.date);
          }
        })
      ],
      onChange: (selectedDates, dateStr, instance) => {
        this.changeFlatpickr(dateStr);
      }
    });
  },

  methods: {
    addClassCss() {
      setTimeout(function () {
        let list = document.getElementsByClassName("arrowTop");
        for (let item of list) {
          let value = item.children[0].children[1].children[0].children[1].children[0].value
          item.children[0].children[1].children[0].children[1].insertAdjacentHTML('beforeend', "<div style='position: absolute;top: 2px;left: 51px;'>年</div>")
          item.children[0].children[1].children[0].children[1].children[0].value = value
        }

      }, 100)
    },

    changeFlatpickr(dateStr) {
      this.optionSelected = "WEEK";
      // this.dayMoment = this.lastDayOfWeek = this.dayMoment = dateStr;
      this.firstDayOfWeek = moment(dateStr)
        .clone()
        .startOf('isoWeek')
        .weekday(1)
        .format("YYYY/MM/DD");
      this.lastDayOfWeek = moment(dateStr)
        .clone()
        .startOf('isoWeek')
        .weekday(7)
        .format("YYYY/MM/DD");

      var days = [];
      var day = moment(this.firstDayOfWeek, "YYYY/MM/DD");
      var week = [];
      while (day <= moment(this.lastDayOfWeek, "YYYY/MM/DD")) {
        days.push(day.clone().format("YYYY-MM-DD"));
        week.push({
          day: day
            .clone()
            .locale("ja")
            .format("dddd")
            .replace("曜日", ""),
          date: day.clone().format("MM/DD")
        });
        day = day.clone().add(1, "d");
      }
      week.unshift({ day: "", date: "" });
      this.arrWeek = week;

      this.arrDayOfWeek = days;
      this.getData();
    },

    async changeOptionCalendar(type) {
      this.optionSelected = type;
      await this.setDate(0);
    },
    setDay() {
      if (this.currentWeek == 0) {
        this.dayMoment = moment()
          .clone()
          .format("YYYY/MM/DD");
      } else if (this.currentWeek > 0) {
        this.dayMoment = moment()
          .clone()
          .add(this.currentWeek, "days")
          .format("YYYY/MM/DD");
      } else {
        this.dayMoment = moment()
          .clone()
          .subtract(Math.abs(this.currentWeek), "days")
          .format("YYYY/MM/DD");
      }
      this.firstDayOfWeek = this.lastDayOfWeek = this.dayMoment;
    },
    setWeekMonth(option, type) {
      if (this.currentWeek == 0) {
        this.firstDayOfWeek = moment()
          .clone()
          .startOf(option)
          .format("YYYY/MM/DD");
        this.lastDayOfWeek = moment()
          .clone()
          .endOf(option)
          .format("YYYY/MM/DD");
      } else if (this.currentWeek > 0) {
        this.firstDayOfWeek = moment()
          .clone()
          .add(this.currentWeek, type)
          .startOf(option)
          .format("YYYY/MM/DD");
        this.lastDayOfWeek = moment()
          .clone()
          .add(this.currentWeek, type)
          .endOf(option)
          .format("YYYY/MM/DD");
      } else {
        this.firstDayOfWeek = moment()
          .clone()
          .subtract(Math.abs(this.currentWeek), type)
          .startOf(option)
          .format("YYYY/MM/DD");
        this.lastDayOfWeek = moment()
          .clone()
          .subtract(Math.abs(this.currentWeek), type)
          .endOf(option)
          .format("YYYY/MM/DD");
      }
      var days = [];
      var day = moment(this.firstDayOfWeek, "YYYY/MM/DD");
      var week = [];
      if (type === "weeks") {
        while (day <= moment(this.lastDayOfWeek, "YYYY/MM/DD")) {
          days.push(day.clone().format("YYYY-MM-DD"));
          week.push({
            day: day
              .clone()
              .locale("ja")
              .format("dddd")
              .replace("曜日", ""),
            date: day.clone().format("MM/DD")
          });
          day = day.clone().add(1, "d");
        }
        week.unshift({ day: "", date: "" });
        this.arrWeek = week;

        this.arrDayOfWeek = days;
      } else {
        this.year = moment(this.firstDayOfWeek, "YYYY/MM/DD").format("YYYY");
        this.month = moment(this.firstDayOfWeek, "YYYY/MM/DD").format("MM");
        this.arrWeek = ["月", "火", "水", "木", "金", "土", "日"];
      }
    },
    async setDate(e) {
      if (e == 1) {
        this.currentWeek += 1;
      } else if (e == -1) {
        this.currentWeek -= 1;
      } else {
        this.currentWeek = 0;
      }
      switch (this.optionSelected) {
        case "DAY":
          this.setDay();
          break;
        case "WEEK":
          this.setWeekMonth("isoWeek", "weeks");
          break;
        case "MONTH":
          this.setWeekMonth("month", "months");
          break;
      }

      await this.getData();
    },
    dropIt() {
      this.isShowSelect = false;
    },
    secondsToHMS(date, start, end) {
      var now = moment(date + " " + start); //todays date
      var end = moment(date + " " + end); // another date
      var duration = moment.duration(now.diff(end));
      return Math.abs(duration.asMinutes()) / 30;
    },

    async getData() {
      this.loading = true;
      let params = this.serialize({
        start_date: this.firstDayOfWeek,
        end_date: this.lastDayOfWeek
      });
      let data = await this.$store.dispatch(GET_DATA_CALENDAR, params);
      if (data.code == SUCCESS) {
        this.data = data.data;
      }
      this.loading = false;
    },

    showDataBooking() {
      let html = "";
      switch (this.optionSelected) {
        case "DAY":
          html = this.showDay();
          break;
        case "WEEK":
          html = this.showWeek();
          break;
        case "MONTH":
          html = this.showMonth();
          break;
      }
      return html;
    },
    showMonth() {
      let dataMonth = this.calendarMonth(this.year, this.month);
      var html = "";
      let numWeek = Math.ceil(dataMonth.length / 7);
      let n = 0;
      for (let i = 0; i < numWeek; i++) {
        html += "<tr>";
        for (let j = n; j < dataMonth.length; j++) {
          let obj = this.data[dataMonth[j].date];
          let date = moment(dataMonth[j].date, "YYYY-MM-DD").format("MM/DD");
          if ((j + 1) % 7 === 0) {
            if (!dataMonth[j].isCurrentMonth) {
              html += `<td class="tg_next_month_ft change_week" attr-date="${dataMonth[j].date}"><div class="text_table_booking" attr-date="${dataMonth[j].date}"><span>${date}</span></div></td>`;
            } else {
              if (obj) {
                if (obj[0].isFullDay == true) {
                  html += `<td class="bg_gray_ver2 change_week" attr-date="${dataMonth[j].date}"><div class="text_table_booking" attr-date="${dataMonth[j].date}"><span>${date}</span></div></td>`;
                } else {
                  html += `<td class="change_week" attr-date="${dataMonth[j].date}"><div class="text_table_booking" attr-date="${dataMonth[j].date}"><span>${date}</span>`;
                  obj.forEach(element => {
                    if (element.type == 1) {
                      html += `<p class="tg_yellow_table2_ft">${element.start_time}~${element.end_time}</p>`;
                    } else {
                      html += `<p class="tg_gray_table2_ft">${element.start_time}~${element.end_time}</p>`;
                    }
                  });
                  html += `</div></td>`;
                }
              } else {
                html += `<td class="change_week" attr-date="${dataMonth[j].date}"><div class="text_table_booking" attr-date="${dataMonth[j].date}"><span>${date}</span></div></td>`;
              }
            }
            n = (i + 1) * 7;
            break;
          } else {
            if (!dataMonth[j].isCurrentMonth) {
              html += `<td class="tg_next_month_ft change_week" attr-date="${dataMonth[j].date}"><div class="text_table_booking" attr-date="${dataMonth[j].date}"><span>${date}</span></div></td>`;
            } else {
              if (obj) {
                if (obj[0].isFullDay == true) {
                  html += `<td class="bg_gray_ver2 change_week" attr-date="${dataMonth[j].date}"><div class="text_table_booking" attr-date="${dataMonth[j].date}"><span>${date}</span></div></td>`;
                } else {
                  html += `<td class="change_week" attr-date="${dataMonth[j].date}"><div class="text_table_booking" attr-date="${dataMonth[j].date}"><span>${date}</span>`;
                  obj.forEach((element, index) => {
                    if (index > 3) {
                      html += `<p>...</p>`;
                    } else {
                      if (element.type == 1) {
                        html += `<p class="tg_yellow_table2_ft">${element.start_time}~${element.end_time}</p>`;
                      } else {
                        html += `<p class="tg_gray_table2_ft">${element.start_time}~${element.end_time}</p>`;
                      }
                    }
                  });
                  html += `</div></td>`;
                }
              } else {
                html += `<td class="change_week" attr-date="${dataMonth[j].date}"><div class="text_table_booking" attr-date="${dataMonth[j].date}"><span>${date}</span></div></td>`;
              }
            }
          }
        }
        html += "</tr>";
      }
      return html;
    },
    showWeek() {
      let html = "";
      for (let k = 0; k < this.arrHour.length; k++) {
        html += "<tr>";
        html += "<td>" + this.arrHour[k] + "</td>";
        for (let i = 0; i < this.arrDayOfWeek.length; i++) {
          if (this.arrHour[k] == '16:00') {
            html += `<td class="bg_gray_ver2" attr-time="${this.arrHour[k]}" attr-date="${this.arrDayOfWeek[i]}"></td>`;
          }
          else if (this.data[this.arrDayOfWeek[i]]) {
            let obj = this.data[this.arrDayOfWeek[i]].find(
              o =>
                o.start_time <= this.arrHour[k] && o.end_time > this.arrHour[k]
            );
            if (obj) {
              if (
                obj.type == 1 &&
                obj.start_time <= this.arrHour[k] &&
                obj.end_time > this.arrHour[k]
              ) {
                const rowSpan = this.secondsToHMS(
                  this.arrDayOfWeek[i],
                  obj.start_time,
                  obj.end_time
                );
                html += `<td class="bg_yellow_ver2"></td>`;
              } else if (
                obj.type == 2 &&
                obj.start_time <= this.arrHour[k] &&
                obj.end_time > this.arrHour[k]
              ) {
                html += `<td class="bg_gray_ver2" attr-time="${this.arrHour[k]}" attr-date="${this.arrDayOfWeek[i]}"></td>`;
              }
            } else {
              html += `<td class="date-choose" attr-date="${this.arrDayOfWeek[i]}" attr-time="${this.arrHour[k]}"></td>`;
            }
          } else {
            html += `<td class="date-choose" attr-date="${this.arrDayOfWeek[i]}" attr-time="${this.arrHour[k]}"></td>`;
          }
        }
        html += "</tr>";
      }

      return html;
    },
    showDay() {
      let html = "";
      const dayFomat = moment(this.dayMoment, "YYYY/MM/DD")
        .clone()
        .format("YYYY-MM-DD");
      for (let k = 0; k < this.arrHour.length; k++) {
        html += "<tr>";
        html += "<td>" + this.arrHour[k] + "</td>";
        if (this.data[dayFomat]) {
          let obj = this.data[dayFomat].find(
            o => o.start_time <= this.arrHour[k] && o.end_time > this.arrHour[k]
          );
          if (obj) {
            if (obj.type == 1 && obj.start_time == this.arrHour[k]) {
              const rowSpan = this.secondsToHMS(
                dayFomat,
                obj.start_time,
                obj.end_time
              );
              if (rowSpan > 1) {
                html += `<td class="bg_yellow_ver2" rowspan="${rowSpan}">${obj.id} ${obj.name} ${obj.description}</td>`;
              } else {
                html += `<td class="bg_yellow_ver2">${obj.id} ${obj.name} ${obj.description}</td>`;
              }
            } else if (
              obj.type == 2 &&
              obj.start_time <= this.arrHour[k] &&
              obj.end_time > this.arrHour[k]
            ) {
              html += '<td class="bg_gray_ver2"></td>';
            }
          } else {
            html += "<td></td>";
          }
        } else {
          html += "<td></td>";
        }
        html += "</tr>";
      }
      return html;
    },
    calendarMonth(year, month) {
      const previousMonthDays = this.createDaysForPreviousMonth(year, month);
      const nextMonthDays = this.createDaysForNextMonth(year, month);
      const currentMonthDays = this.createDaysForCurrentMonth(
        year,
        month,
        moment(`${year}-${month}-01`).daysInMonth()
      );
      const days = [
        ...previousMonthDays,
        ...currentMonthDays,
        ...nextMonthDays
      ];
      return days;
    },

    funcGetWeekDay(date) {
      return moment(date).weekday();
    },

    createDaysForPreviousMonth(year, month) {
      const firstDayOfTheMonthWeekday = this.funcGetWeekDay(
        this.currentMonthDays(year, month)[0].date
      );

      const previousMonth = moment(`${year}-${month}-01`).subtract(1, "month");

      // Cover first day of the month being sunday (firstDayOfTheMonthWeekday === 0)
      const visibleNumberOfDaysFromPreviousMonth = firstDayOfTheMonthWeekday
        ? firstDayOfTheMonthWeekday - 1
        : 6;

      const previousMonthLastMondayDayOfMonth = moment(
        this.currentMonthDays(year, month)[0].date
      )
        .subtract(visibleNumberOfDaysFromPreviousMonth, "day")
        .date();

      return [...Array(visibleNumberOfDaysFromPreviousMonth)].map(
        (day, index) => {
          return {
            date: moment(
              `${previousMonth.year()}-${previousMonth.month() +
                1}-${previousMonthLastMondayDayOfMonth + index}`
            ).format("YYYY-MM-DD"),
            dayOfMonth: previousMonthLastMondayDayOfMonth + index,
            isCurrentMonth: false
          };
        }
      );
    },

    currentMonthDays(year, month) {
      return this.createDaysForCurrentMonth(
        year,
        month,
        moment(`${year}-${month}-01`).daysInMonth()
      );
    },

    createDaysForCurrentMonth(year, month) {
      return [...Array(this.getNumberOfDaysInMonth(year, month))].map(
        (day, index) => {
          return {
            date: moment(`${year}-${month}-${index + 1}`).format("YYYY-MM-DD"),
            dayOfMonth: index + 1,
            isCurrentMonth: true
          };
        }
      );
    },

    getNumberOfDaysInMonth(year, month) {
      return moment(`${year}-${month}-01`).daysInMonth();
    },

    createDaysForNextMonth(year, month) {
      const lastDayOfTheMonthWeekday = this.funcGetWeekDay(
        `${year}-${month}-${this.currentMonthDays(year, month).length}`
      );

      const nextMonth = moment(`${year}-${month}-01`).add(1, "month");

      const visibleNumberOfDaysFromNextMonth = lastDayOfTheMonthWeekday
        ? 7 - lastDayOfTheMonthWeekday
        : lastDayOfTheMonthWeekday;

      return [...Array(visibleNumberOfDaysFromNextMonth)].map((day, index) => {
        return {
          date: moment(
            `${nextMonth.year()}-${nextMonth.month() + 1}-${index + 1}`
          ).format("YYYY-MM-DD"),
          dayOfMonth: index + 1,
          isCurrentMonth: false
        };
      });
    },

    urlImage(img) {
      let url = `${ENV.PREFIX_FOLDER}` + img;
      return url;
    },
    redirectToRental() {
      this.$router.push({ name: 'rental-fee'})
    },
    showModalMyBooking() {
      this.checkPopupFindBooking = true;
    },
    closePopupFindBooking() {
      this.errors = [];
      this.resetFormFindBooking();
      this.checkPopupFindBooking = false;
    },
    resetFormFindBooking(){
      this.formFindBooking = {
        code:  '',
        email: '',
      }
    },
    async findBooking() {
      this.errors = [];
      const data = await this.$store.dispatch(GET_DATA_FIND_BOOKING, this.formFindBooking);
      if(data.code == SUCCESS) {
          this.id_order = data.data.id_hash;
        this.$router.push({ name: 'order-detail', params: {id: this.id_order} })
      }else if(data.code == BAD_REQUEST){
        this.checkError = true;
        this.errors = data.data
        if(this.errors['email'] && !this.errors['code']){
          this.checkError = false;
        }
      } else if (data.code == HTTP_BAD_REQUEST) {
        this.errors = data.data;
      }
    },

    goToContact() {
      window.open("http://www.kox-radio.jp/takeoff7/contactform/", '_blank');
    },

    async checkStatusBooking() {
      this.loading = true;
      let params = this.serialize({
        token: this.$route.query.token
      });
      let data = await this.$store.dispatch(CHECK_STATUS_BOOKING, params);
      if (data.code == SUCCESS) {
        this.checkPopupStatusBooking = true;
        this.dataBooking = data.data;
      } else if (data.code == NOT_FOUND) {
        this.notifyError(this.$t('ER_14'))
      } else {
        this.notifyError(this.$t('errorServer'))
      }
      this.loading = false;
    },

    closePopupErr01() {
      this.checkNotiErr01 = false
    },

    closePopupErr10() {
      this.checkNotiErr10 = false
    },
  }
};
</script>
<style>
/* ul.tg_my_booking_fee li:nth-child(1) a {
  background: #3190d2 !important;
}
ul.tg_my_booking_fee li:nth-child(2) a {
  background: #f69600 !important;
} */

.show-date {
  display: block !important;
}
.slick-next {
  top: 50% !important;
  right: -50px !important;
  transform: translate(-50%, -50%) !important;
  margin: 0px !important;
}

.slick-prev {
  top: 50% !important;
  left: -8px !important;
  transform: translate(-50%, -50%) !important;
  margin: 0px !important;
}
.popup_bos14{
  display: none;
}
.popup_bos13{
  display: none;
}
.popup_er_01{
  display: none;
}
.popup_er_10{
  display: none;
}
.list_group.error-message-custom {
  color: red;
  padding-bottom: 10px;
}
.list_group.error-message-custom {
  display: block;
  margin-top: -10px;
}

.popup_er_10 .form_group span{
  font-family: 17px;
  line-height: 25px;
  font-family: kozgopr6n-medium;
  display: block;
  margin: 0px 0px 15px 0px;
}

.popup_er_01 .form_group span{
  font-family: 17px;
  line-height: 25px;
  font-family: kozgopr6n-medium;
  display: block;
  margin: 0px 0px 15px 0px;
}

.btnCloseErr {
    font-family: kozgopr6n-medium;
    /* font-size: 21px; */
    line-height: 29px;
    color: #fff;
    background: #3190d2;
    padding: 5px 20px 11px 20px;
    border-radius: 10px;
    border: none;
}
p.tg_month_ver2{
  font-size: 18px;
  line-height: 26px;
  margin: 10px 72px 0px 0px;
  text-align: right;
}
.flatpickr-current-month .numInputWrapper{
  width: 5ch !important;
}
ul.tg_color_code_nsx li.tg_gray::before {
  background: #d5d5d5;
}
</style>
